import React from 'react'
import styled from 'styled-components'

const EventsContainer = styled.div`
  .event {
    /* background: #efefef; */
    padding: 10px;
    margin-bottom: 10px;
    border-bottom: 1px solid #ededed;
  }
  .title {
    text-decoration: underline;
  }
  h3 {
    font-size: 1.2rem;

    margin: 0 0 5px 0;
  }
  p {
    margin: 0 0 5px 0;
  }
  a {
    text-decoration: none;
    color: #339966;
  }
`

const Events = ({ event, currentEvent, title }) => (
  <EventsContainer>
    <h2 className="title">{title}</h2>
    {event.map(event => (
      <div key={event.node.id}>
        {event.node.acf.upcoming_past === currentEvent && (
          <div className="event">
            <h3>{event.node.title}</h3>
            {event.node.acf.upcoming_past === 'Upcoming' && (
              <>
                {event.node.acf.event_dates && <p className="eventDate">{event.node.acf.event_dates}</p>}
                {event.node.acf.event_times && <p className="eventTime">{event.node.acf.event_times}</p>}
                {event.node.acf.event_location && <p className="eventLocation">{event.node.acf.event_location}</p>}
                {event.node.acf.event_location_2 && (
                  <p className="eventLocation eventLocation2">{event.node.acf.event_location_2}</p>
                )}
                {event.node.acf.event_link_display && (
                  <a href={event.node.acf.event_link} target="_blank" rel="noopener noreferrer">
                    {event.node.acf.event_link_display}
                  </a>
                )}
              </>
            )}
          </div>
        )}
      </div>
    ))}
  </EventsContainer>
)

export default Events
