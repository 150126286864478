import React from 'react'
import { graphql } from 'gatsby'
import styled from 'styled-components'
import Img from 'gatsby-image'
import Layout from '../components/layout'
import Events from '../components/Events'
import EventsNarrativeBlock from '../components/eventsNarrativeBlock'
import ContentBlock from '../components/contentBlock'
import SEO from '../components/seo'

const EventBlock = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(350px, 1fr));
  text-align: center;
  grid-gap: 20px;
  border-bottom: 1px solid #bbb;
`

const EventsPage = ({ data }) => (
  <Layout>
    <SEO title={data.wordpressPage.title} />
    <ContentBlock>
      <h1>{data.wordpressPage.title}</h1>
    </ContentBlock>
    <EventBlock>
      <Events event={data.allWordpressWpEvents.edges} currentEvent="Upcoming" title="Upcoming Shows" />
      <div className="featuredImage">
        <Img fluid={data.wordpressPage.featured_media.localFile.childImageSharp.fluid} />
      </div>
      <Events event={data.allWordpressWpEvents.edges} currentEvent="Past" title="Previous Shows" />
    </EventBlock>

    <EventsNarrativeBlock
      dangerouslySetInnerHTML={{
        __html: data.wordpressPage.content,
      }}
    />
  </Layout>
)

export default EventsPage

export const query = graphql`
  query EventsQuery {
    allWordpressWpEvents {
      edges {
        node {
          title
          id
          acf {
            event_dates
            event_link
            event_link_display
            event_location
            event_location_2
            event_times
            upcoming_past
          }
        }
      }
    }
    wordpressPage(slug: { eq: "shows-exhibitions" }) {
      title
      content
      featured_media {
        localFile {
          childImageSharp {
            fluid(maxHeight: 500) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  }
`
