import Styled from 'styled-components'

const EventsNarrativeBlock = Styled.div`
  margin-top: 20px;
  img{
    margin-bottom: 0;
  }
  .caption-text{
    font-size: .8rem;
    color: #339966;
  }
  .eventContent{
    display: grid;
    grid-template-columns: 1fr 2fr 1fr;
    grid-gap: 20px;
    margin-bottom: 20px;
    @media (max-width: 480px){
      grid-template-columns: 1fr;
    }
    p{
      margin-bottom: 0;
    }
  }
  .gallery{
    margin-bottom: 20px;
    img{
      width: 45%;
    }
  }
  @media (max-width: 400px){
    div{
      width: 100% !important;
    }
    .alignleft, .alignright{
    max-width: 100%;
  }
    .gallery{
      flex-wrap: wrap;
      img{
        width: 100%;
        margin-bottom: 10px;
      }
    }
  }

`

export default EventsNarrativeBlock
